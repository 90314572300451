import style from './style.module.css';

import Container from '@components/Container';
import Logo from '@components/Logo';
import SocialMedia from '@components/SocialMedia';

const Footer = () => {   

    const navLinks = [
        {
            name: 'Повышение ранга',
            link: '/matchmakingboost',
        },
        {
            name: 'FACEIT Boost',
            link: '/faceitboost',
        },
        {
            name: 'Аккаунты',
            link: '/cs2accounts',
        },
        {
            name: 'Контакты',
            link: '/contacts',
        },
        {
            name: 'Блог',
            link: '/blog'
        }
        
    ]

    return(
        <footer>
            <Container additStyles={style.footer__container}>
                <div className={style.footer__line}>
                    <div className={style.footer__col}>
                        <Logo src="/img/footer-logo.png" />
                    </div>
                    <div className={style.footer__col}>
                        <nav className={style.footer__nav}>
                            {navLinks.map((link, idx)=> {
                                return <a className={style.footer__nav_link} href={link.link} key={idx}>
                                    {link.name}
                                </a>
                            })}
                        </nav>
                        <div className={style.footer__social_media}>
                            <SocialMedia />
                        </div>
                        <div className={style.footer__payment}>
                            <a href='https://www.trustpilot.com/review/divineboost.org' className={style.footer__trust}>
                                <img src='/img/trust.png' alt="trust" />
                            </a>
                            <a href='https://vk.com/topic-189703162_40247198' className={style.footer__trust}>
                                <img src='/img/vk-otzivi.png' alt="Отзывы" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className={style.footer__line}>
                    <div className={style.footer__col}>
                        <div className={style.footer__info}>
                            © 2015-2023 divineboost.org <br />
                            Counter-Strike: Global Offensive является зарегистрированной торговой маркой компании Valve Corporation. <br />
                            Мы никоим образом не связаны с Valve Corporation и не поддерживаем ее. <br />
                            Все права защищены. <br /> <br />
                        </div>
                    </div>
                </div>
            </Container>
        </footer>
    )
    
}

export default Footer;