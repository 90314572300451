import style from './style.module.css';

const WorkItem = ({src, alt, title, text}) => {
    return(
        <div className={style.work_item}>
            <img src={src} alt={alt} className={style.work_item__icon} />
            <h4 className={style.work_item__title}>
                {title}
            </h4>
            <p className={style.work_item__text}>
                {text}
            </p>
        </div>
    )
}

export default WorkItem;