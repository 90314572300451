import { useEffect, useState } from 'react';

import style from './style.module.css';
import 'rc-slider/assets/index.css';
import './style.css';

import { Range } from 'rc-slider';
import Checkbox from '@components/Checkbox';
const Calculator = ({data, min, max, type, initialValues, onChangeInfo}) => {

    let [sliderValue, setSliderValue] = useState(initialValues);
    let [price, setPrice] = useState(0)
    let [isLobbyBoost, setIsLobbyBoost] = useState(false);
    let [isExpressBoost, setIsExpressBoost] = useState(false);
    let [lvlFirst, setLvlFirst] = useState(1);
    let [lvlSecond, setLvlSecond] = useState(2);
    let [isCalibrate, setIsCalibrate] = useState(false);
    let [isPartyCalibrate, setIsPartyCalibrate] = useState(false);
    let sliderFirstValue = sliderValue[0];
    let sliderSecondValue = sliderValue[1];
    let priceWithoutPercent = 0;
    const LOBBY_PERCENT = 50;
    const EXPRESS_PERCENT = 20;
    const SLIDER_DEFAULT_VALUE = initialValues;
    function calc(minElo, maxElo) {
        let result = 0;
        const table = {
            [[3001, 3200]] : 12000 / 199,  // 10 lvl
            [[2801, 3001]] : 10000 / 200,  // 10 lvl
            [[2601, 2801]] : 8000 / 200,  // 10 lvl
            [[2401, 2601]] : 6000 / 200,  // 10 lvl
            [[2201, 2401]] : 4000 / 200,   // 10 lvl
            [[2001, 2201]] : 3000 / 200,   // 10 lvl
            [[1751, 2001]] : 1900 / 250,   // 9 lvl
            [[1531, 1751]] : 1300 / 220,   // 8 lvl
            [[1351, 1531]] : 1000 / 180,   // 7 lvl
            [[1201, 1351]] :  800 / 150,   // 6 lvl
            [[1051, 1201]] : 700 / 150,   // 5 lvl
            [[901, 1051]] : 600 / 150,   // 4 lvl
            [[751, 901]] : 600 / 150,    // 3 lvl
            [[501, 751]] : 600 / 250,     // 2 lvl
            [[0, 501]] : 1100 / 500        // 1 lvl
        };
        // Каждую единицу эло мы будем считать по принодлежности к своему диапазону
        for(let i = minElo + 1; i <= maxElo; i++) {
            for (const [k, v] of Object.entries(table)) {
                const range = k.split`,`.map(x => +x);
                if (i >= range[0] && i <= range[1]) {
                    result += v;
                    break;
                }
            }
        }
        return Math.round(result);
    }

    const calculatePrice = (value) => {
        let price = 0;

        if(type === "mm") {
            for(let i = value[0] - 1; i < value[1] - 1; i++) {
                price += data[i].price;
                priceWithoutPercent += data[i].price
            }
    
        } else {
            for(var i = 0; i < data.length; i++) {
                if(data[i].elo < value[0]) {
                    setLvlFirst(i);
                }

                if(data[i].elo < value[1]) {
                    setLvlSecond(i)
                }

            }

            price = calc(value[0], value[1]);
            priceWithoutPercent = price;
        }
        
        if(isLobbyBoost) {
            price += (priceWithoutPercent / 100 * LOBBY_PERCENT);
        }

        if(isExpressBoost) {
            price += (priceWithoutPercent / 100 * EXPRESS_PERCENT);
        }

        return price;
    }
    const onSliderChange = (value) => {
        setSliderValue(value);
        let price = calculatePrice(value);
        setPrice(price);
        onChangeInfo({
            from: type === 'mm' ? data[sliderFirstValue - 1].name : sliderFirstValue,
            to: type === 'mm' ? data[sliderSecondValue - 1].name : sliderSecondValue,
            price: price,
            lobby: isLobbyBoost,
            express: isExpressBoost,
        })
    }

    const onLobbyBoost = () => {
        setIsLobbyBoost(!isLobbyBoost);
    }

    const onExpressBoost = () => {
        setIsExpressBoost(!isExpressBoost);     
    }

    useEffect(() => {
        let price = calculatePrice(sliderValue);
        setPrice(price);
    }, [])

    useEffect(() => {
        let price = calculatePrice(sliderValue);
        setPrice(price);
        onChangeInfo({
            from: type === 'mm' ? data[sliderFirstValue - 1].name : sliderFirstValue,
            to: type === 'mm' ? data[sliderSecondValue - 1].name : sliderFirstValue,
            price: price,
            lobby: isLobbyBoost,
            express: isExpressBoost,
        }) 
    }, [isLobbyBoost])

    useEffect(() => {
        let price = calculatePrice(sliderValue);
        setPrice(price);
        onChangeInfo({
            from: type === 'mm' ? data[sliderFirstValue - 1].name : sliderFirstValue,
            to: type === 'mm' ? data[sliderSecondValue - 1].name : sliderFirstValue,
            price: price,
            lobby: isLobbyBoost,
            express: isExpressBoost,
        }) 
    }, [isExpressBoost])
    const onFirstChange = (event) => {
        let value = Number(event.target.value);
        if (value < min) {
            value = min
        }
        if (value > max) {
            value = max
        }
        if (value > sliderSecondValue) {
            value = sliderSecondValue
        }
        setSliderValue([value, sliderSecondValue]);
        let price = calculatePrice([value, sliderSecondValue]);
        setPrice(price);
    }
    const onSecondChange = (event) => {
        let value = Number(event.target.value);
        if (value < min) {
            value = min
        }
        if (value > max) {
            value = max
        }
        setSliderValue([sliderFirstValue, value]);
        let price = calculatePrice([sliderFirstValue, value]);
        setPrice(price);
    }
    const onCalibrate = () => {
        setIsCalibrate(!isCalibrate);
        setIsPartyCalibrate(false);
    }
    const onPartyCalibrate = () => {
        setIsPartyCalibrate(!isPartyCalibrate);
        setIsCalibrate(false);
    }
    
    return(
        <div>
            <div className={style.calculator__ranks}>
                {type === "mm" ?
                    <>
                        <div className={style.calculator__col}>
                            <h4 className={style.calculator__ratingname}>Текущий рейтинг</h4>
                            <img className={style.calculator__image} src={data[sliderFirstValue - 1].src}/>
                            <span className={style.calculator__rankname}>
                                {data[sliderFirstValue - 1].name}
                            </span>
                        </div>
                        <div className={style.calculator__col}>
                            <h4 className={style.calculator__ratingname}>Желаемый рейтинг</h4>
                            <img className={style.calculator__image} src={data[sliderSecondValue - 1].src} />
                            <span className={style.calculator__rankname}>
                                {data[sliderSecondValue - 1].name}
                            </span>
                        </div> 
                    </>
                    
                :
                    <>
                        <div className={style.calculator__col_f} >
                            <img src={data[lvlFirst].src} className={style.calculator__image_lvl} />
                            <div className={style.calculator__counter}>
                                <h3>Текущий рейтинг</h3>
                                <input className={style.calculator__count_input} value={sliderFirstValue} onChange={onFirstChange} />
                            </div>
                        </div>
                        <div className={style.calculator__col_f}>
                            <img src={data[lvlSecond].src} className={style.calculator__image_lvl} />
                            <div className={style.calculator__counter}>
                                <h3>Желаемый рейтинг</h3>
                                <input className={style.calculator__count_input} value={sliderSecondValue} onChange={onSecondChange} />
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className={style.calculator__range}>
                <Range
                    min={min}
                    max={max}
                    value={sliderValue}
                    onChange={onSliderChange}
                    defaultValue={SLIDER_DEFAULT_VALUE}
                    allowCross={false}
                />
            </div>
            <div className={style.calculator__discount_line}>
                <div className={style.calculator__checkbox}>
                    <Checkbox onCheckBoxClick={onLobbyBoost} />
                    <span className={style.calculator__label}>
                        Буст в лобби (+50%)
                    </span>
                </div>
                <div className={style.calculator__checkbox}>
                    <Checkbox onCheckBoxClick={onExpressBoost} />
                    <span className={style.calculator__label}>
                        Экспресс буст (+20%)
                    </span>
                </div>
                {type === "mm" && (
                    <>
                        <div className={style.calculator__checkbox}>
                            <Checkbox value={isCalibrate} onCheckBoxClick={onCalibrate} />
                            <span className={style.calculator__label}>
                                Калибровка акканта (+€25)
                            </span>
                        </div>
                        <div className={style.calculator__checkbox}>
                            <Checkbox value={isPartyCalibrate} onCheckBoxClick={onPartyCalibrate} />
                            <span className={style.calculator__label}>
                                Калибровка акканта в патти (+€35)
                            </span>
                        </div>
                    </>
                )}
            </div>
            <span className={style.calculator__price}>
                Итоговая цена: <span>{price} РУБ</span>
            </span>
        </div>
    )
}

export default Calculator;