import style from './style.module.css';

import Container from '@components/Container';
import Title from '@components/Title';
import WorkItem from '../WorkItem';

const WorksSection = () => {

    const workItems = [
        {
            title: "Заполните форму и нажмите «Добавить в корзину»",
            text: `Цена будет рассчитана автоматически на основе
             вашего текущего и желаемого ранга.
             Рекомендуется, но не обязательно, временно
             сменить пароль во время буста.`,
            src: '/img/work1.png',
            alt: 'cart'
        },
        {
            title: "Заполните форму и нажмите «Добавить в корзину»",
            text: `Вы будете перенаправлены на безопасную и надежную
             страницу оформления заказа, где вы сможете выбрать свой
             вариант оплаты.
             PayPal — один из крупнейших онлайн-платежей
             провайдерами по всему миру и на 100% безопасно.`,
            src: '/img/work2.png',
            alt: 'check'
        },
        {
            title: "Заполните форму и нажмите «Добавить в корзину»",
            text: `Наш бустер будет закреплен за вашим заказом в течение
             ~2 часов. Пожалуйста, будьте терпеливы, обычно бустеры
             посреди других заказов, и им приходится
             закончить свою текущую работу, чтобы начать новый буст.`,
            src: '/img/work3.png',
            alt: 'man'
        },
    ]

    return(
        <section className={style.works_section}>
            <Container>
                <div className={style.works_section__title}> 
                    <Title TAG="h3" text="Как это работает?" />
                </div>
                <span className={style.works_section__sub}>
                    Всего 3 простых и быстрых шага, чтобы повысить свой рейтинг.
                </span>
                <div className={style.works_section__items}>
                    {workItems.map((item, idx) => {
                        return <WorkItem key={idx} title={item.title} alt={item.alt} src={item.src} text={item.text} />
                    })}
                </div>
            </Container>
        </section>
    )
}

export default WorksSection;