import style from './style.module.css';

import {
    Link
} from "react-router-dom";  

const Nav = () => {  

    const routes = [
        {
            name: 'Буст Cs2',
            route: '/matchmakingboost',
        },
        {
            name: 'Буст Faceit',
            route: '/faceitboost',
        },
        {
            name: 'Аккаунты',
            route: '/cs2accounts',
        },
        {
            name: 'Контакты &  О нас',
            route: '/contacts',
        },
        {
            name: 'Блог',
            route: '/blog',
        },
    ]

    return(
        <nav>
            {routes.map((link, idx) => {
                return(
                    <Link to={link.route} key={idx} className={style.nav__link}>
                        {link.name}
                    </Link>
                )
            })}
        </nav>
    )
}

export default Nav;