import style from "./style.module.css";
import Header from "@components/Header";
import CalculatorMM from "@components/CalculatorMM";
import Container from "@components/Container";
import Button from "@components/Button";
import AdvSection from "@components/AdvSection";
import DescSection from "@components/DescSection";
import CalcDesc from "@components/CalcDesc";
import Footer from "@components/Footer"
import Modal from "@components/Modal";

import { useState } from "react";

const MMBoostingPage = () => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [info, setInfo] = useState({
        from: 0,
        to: 0,
        price: 0,
        lobby: false,
        express: false,
    })

    const ranks = [
        {
            id: 1,
            name: 'Silver 1',
            src: 'img/ranks/silver1.jpg',
            price: 4,
        },
        {
            id: 2,
            name: 'Silver 2',
            src: 'img/ranks/silver2.jpg',
            price: 4,
        },
        {
            id: 3,
            name: 'Silver 3',
            src: 'img/ranks/silver3.jpg',
            price: 4,
        },
        {
            id: 4,
            name: 'Silver 4',
            src: 'img/ranks/silver4.jpg',
            price: 4,
        },
        {
            id: 5,
            name: 'Silver Elite',
            src: 'img/ranks/silver5.jpg',
            price: 4,
        },
        {
            id: 6,
            name: 'Silver Elite Master',
            src: 'img/ranks/silver6.jpg',
            price: 5,
        },
        {
            id: 7,
            name: 'Gold Nova 1',
            src: 'img/ranks/goldnova1.jpg',
            price: 5,
        },
        {
            id: 8,
            name: 'Gold Nova 2',
            src: 'img/ranks/goldnova2.jpg',
            price: 5,
        },
        {
            id: 9,
            name: 'Gold Nova 3',
            src: 'img/ranks/goldnova3.jpg',
            price: 5,
        },
        {
            id: 10,
            name: 'Gold Nova Master',
            src: 'img/ranks/goldnovamaster.jpg',
            price: 6,
        },
        {
            id: 11,
            name: 'Master Guardian 1',
            src: 'img/ranks/masterguardian1.jpg',
            price: 6,
        },
        {
            id: 12,
            name: 'Master Guardian 2',
            src: 'img/ranks/masterguardian2.jpg',
            price: 6,
        },
        {
            id: 13,
            name: 'Master Guardian Elite',
            src: 'img/ranks/masterguardianelite.jpg',
            price: 7,
        },
        {
            id: 14,
            name: 'Distinguished Master Guardian',
            src: 'img/ranks/distinguishedmasterguardian.jpg',
            price: 9,
        },
        {
            id: 15,
            name: 'Legendary Eagle',
            src: 'img/ranks/legendaryeagle.jpg',
            price: 11,
        },
        {
            id: 16,
            name: 'Legendary Eagle Master',
            src: 'img/ranks/legendaryeaglemaster.jpg',
            price: 18,
        },
        {
            id: 17,
            name: 'Supreme Mastre First Class',
            src: 'img/ranks/suprememasterfirstclass.jpg',
            price: 22,
        },
        {
            id: 18,
            name: 'Global ELITE',
            src: 'img/ranks/globalelite.jpg',
            price: 4,
        },
    ]

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(false);
    }

    const onChangeInfo = (info) => {
        setInfo({
            from: info.from,
            to: info.to,
            price: info.price,
            lobby: info.lobby,
            express: info.express,
        })
    }

    return(
        <div>
            <Modal 
                isOpened={isModalOpen}
                closeModal={closeModal}
                from={info.from}
                to={info.to}
                price={info.price}
                express={info.express}
                lobby={info.lobby}
            />
            <Header type="mini" />
            <CalcDesc
                title="MatchMaking Буст"
                desc={`
                    Наши цены конкурентоспособны, наши услуги высокого качества и мы известны своей оперативностью
                     и эффективность.
                     Повышение ранга Cs 2 осуществляется как global elite, так и полупрофессиональными игроками FPL-C.
                     Это не буст Vertigo, это настоящий буст против реальных игроков.
                `}
            />
            <Container additStyles={style.mmboostpage}>
                <div className={style.mmboostpage__calculator}>
                    <h3>Расчет стоимости</h3>
                    <CalculatorMM data={ranks} min={1} max={18} onChangeInfo={onChangeInfo}/>
                    <div className={style.mmboostpage__button} onClick={openModal}>
                        <Button text="Купить" icon="cart" link="/" TAG="div" />
                    </div>
                </div>
            </Container>
            <AdvSection type="noinfo" />
            <DescSection />
            <Footer />
        </div>
    )
}

export default MMBoostingPage;