import style from './style.module.css';

import Container from '@components/Container';
import BoostCard from '@components/BoostCard';
import Button from '@components/Button';

const BoostSection = () => {

    const boostCards = [
        {
            src: 'img/mm.jpg',
            name: '',
            link: '/matchmakingboost'
        },
        {
            src: 'img/faceit.jpg',
            name: 'Faceit Boosting',
            link: '/faceitboost'
        },

    ]

    let boostCardsItems = boostCards.map((card, idx) => {
        return(
            <BoostCard key={idx} image={card.src} link={card.link} text={card.name}/>
        )
    })

    return (
        <section className={style.boost_section}>
            <Container additStyles={style.boost_section__container}>
                {boostCardsItems}
            </Container>
            <Button text="Аккаунты & Профили" link="/accounts" />
        </section>
    )
}

export default BoostSection;