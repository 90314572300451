import style from './style.module.css';

import Container from '@components/Container';
import Title from '@components/Title';
import {AccordionItem, AccordionWrapper} from 'custom-react-accordion';

const AccordionSection = () => {

    const data = [
        {
            "title": "Никаких сторонних программ",
            "description": `Миссия нашей компании – предлагать законное,
            безопасное и надежное качество обслуживания наших клиентов. Таким образом, мы
            категорически против любого возможного использования сторонних программ, чтобы обезопасить вас
            от любого неудовлетворительного опыта, наряду с нашей надежной
            репутацией и преданностью нашим клиентам. `
          },
          {
            "title": "Безопасен ли бустинг? могу ли я получить бан?",
            "description": `Продвижение от нашей компании на 100% безопасно, работа делается в первую очередь для того, чтобы наши клиенты были довольны,
             а не наоборот. Мы стремимся сделать ваш опыт повышения квалификации максимально приятным и безопасным. `
          },
          {
            "title": "Как мне приобрести буст?",
            "description": `Чтобы приобрести буст, пожалуйста, выберите вариант бустинга, который вы хотите получить. 
            После этого перейдите в раздел "Контакты" на нашем веб-сайте и свяжитесь с нами наиболее удобным из доступных способов связи. `
          },
          {
            "title": "Сколько времени займет мое повышение?",
            "description": `Это зависит от сложности вашего заказа, однако мы можем гарантировать, что наши бустеры выигрывают от 6 до 10 игр в день! `
          },
          {
            "title": "Мне не нравится цена, могу ли я получить скидку?",
            "description": `
            Если вас беспокоит цена, указанная в вашем заказе, пожалуйста, свяжитесь с нами наиболее удобным способом связи, доступным на нашем веб-сайте. `
          },
          {
            "title": "Я сделал заказ. Когда это начнется?",
            "description": `После того, как вы оформите свой заказ, мы приступим к выполнению вашего заказа в зависимости от имеющейся рабочей нагрузки. Обычно мы начинаем бустинг после размещения заказа.`
          },
    ]

    return(
        <div className={style.accordion_section}>
            <Container>
                <div className={style.accordion_section__title}>
                    <Title text="Прокачка CSGO – Часто задаваемые вопросы" TAG="h3" />
                </div>
                
                <AccordionWrapper>
                    {data.map((item, index) => (
                        <AccordionItem key={index} index={index} title={item.title} description={item.description} />
                    ))}
                </AccordionWrapper>
            </Container>
        </div>
    )
}

export default AccordionSection;