import style from './style.module.css';

const ResultItem = ({text, link, src, alt}) => {
    return(
        <a className={style.result_item} href={src} target="_blank" rel="noreferrer">
            <div className={style.result_item__image_wrap}>
                <img className={style.result_item__image} src={src} alt={alt}/>
            </div>
            <span className={style.result_item__text}>
                {text}
            </span>
        </a>
    )
}

export default ResultItem;
