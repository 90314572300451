import './../../style.css';
import './../../fonts/stylesheet.css';
import './../../Accordion.css';

import MainPage from '@components/MainPage';
import MMBoostingPage from '@components/MMBoosingPage';
import FaceitBoostingPage from '@components/FaceitBoostingPage';
import AccountsPage from '@components/AccountsPage';
import ContactsPage from '@components/ContactsPage';
import SellPage from '@components/SellPage'; 
import BlogPage from "@components/BlogPage";

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ArticlePage from "../BlogCSGOPage";
import BlogCS2Page from "../BlogCS2Page";
import BlogSkins from "../BlogSkins";
import BlogConfigCs2 from "../BlogConfigCs2";

const App = () => {
    return(
        <>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <MainPage />
                    </Route>
                    <Route path="/matchmakingboost">
                        <MMBoostingPage />
                    </Route>
                    <Route path="/faceitboost">
                        <FaceitBoostingPage />
                    </Route>
                    <Route path="/cs2accounts">
                        <AccountsPage />
                    </Route>
                    <Route path="/contacts">
                        <ContactsPage />
                    </Route>
                    <Route path="/acc/:addId">
                        <SellPage />
                    </Route>
                    <Route path="/blog">
                        <BlogPage/>
                    </Route>
                    <Route path="/best-CS-GO-launch-options">
                        <ArticlePage/>
                    </Route>
                    <Route path="/CS-2-options-to-increase-fps">
                        <BlogCS2Page/>
                    </Route>
                    <Route path="/the-best-white-skins-in-cs">
                        <BlogSkins/>
                    </Route>
                    <Route path="/config-cs2">
                        <BlogConfigCs2/>
                    </Route>
                </Switch>
            </Router>
        </>
                
    )
}

export default App;