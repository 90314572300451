import style from './style.module.css';

const Logo = ({src}) => {
    return(
        <a  className={style.logo} href="/">
            <img src={src} alt="divine boost"/>
        </a>
    )
}

export default Logo;