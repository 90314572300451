
import Header from '@components/Header';
import BoostSection from '@components/BoostSection';
import AdvSection from '@components/AdvSection';
import ResultsSection from '@components/ResultsSection';
import AccordionSection from '@components/AccordionSection';
import Footer from '@components/Footer';

const MainPage = () => {
    return(
        <>
            <Header />
            <BoostSection />
            <AdvSection />
            <AccordionSection />
            <ResultsSection />
            <Footer />
        </>
    )
}

export default MainPage;