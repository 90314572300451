import style from './style.module.css';

const Title = ({TAG, text, lcase="upper"}) => {
    return(
        <TAG className={lcase === "upper" ? `${style.title}` : `${style.title} ${style.title__low}`}>
            {text}
        </TAG>
    )
    
}

export default Title;