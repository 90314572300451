import style from "./style.module.css";
import Header from '@components/Header';
import Container from '@components/Container';
import Footer from '@components/Footer';

const BlogSkins = () => {
    return (
    <div>
        <Header type="mini"/>
        <section className={style.contacts_section}>
            <Container>
                <div className={style.content_article}>
                    <h2>Лучшие Белые Скины В CS</h2>

                    <h3>USP-S Whiteout, лучший белый скин на пистолет</h3>
                    <p>В белом инвентаре нельзя обойтись без скина из семейства Whiteout. Белый скин быстро покрывается царапинами с ростом float, поэтому стоит искать экземпляры в состоянии Minimal Wear или Field-Tested.</p>
                    <img src="/img/Usp.webp" alt="usp"/>

                    <h3>AK-47 Asiimov, лучший белый скин на АК</h3>
                    <p>Среди «калашей» с белым цветом сложно выбрать лучшего кандидата AK-47 Asiimov.</p>
                    <img src="/img/Ak.webp" alt="ak-47"/>

                    <h3> M4A1-S Printstream, лучший белый скин на М4А1-S</h3>
                    <p>Среди «эмок» с белым цветом сложно выбрать лучшего кандидата M4A1-S Printstream.</p>
                    <img src="/img/m4a1.webp" alt="m4a1-s"/>

                    <h3>AWP Asiimov, лучший белый скин на AWP</h3>
                    <p>Еще один скин Asiimov, но для «авика». Как и в случае с AK-47, большую часть этого скина занимает чистейший белый цвет, а отдельные элементы винтовки окрашены в оранжевый и черный.</p>
                    <img src="/img/awp.webp" alt="ak-47"/>

                </div>
            </Container>
        </section>
        <Footer/>
    </div>
    )
}

export default BlogSkins;