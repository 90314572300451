import style from './style.module.css';

const Button = ({text, link, type="default", icon="none", TAG}) => {

    if(!TAG) {
        return(
            <a href={link} className={type === 'default' ? style.button : style.button_white}>
                {icon === 'cart' ? <img src='img/cart.png' /> : null}
                {text}
            </a>
        )
    }

    if(TAG) {
        return(
            <TAG className={type === 'default' ? style.button : style.button_white}>
                {icon === 'cart' ? <img src='img/cart.png' /> : null}
                {text}
            </TAG>
        )
    }
    
}

export default Button;