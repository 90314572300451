import style from './style.module.css';

import Header from '@components/Header';
import Title from '@components/Title';
import Container from '@components/Container';
import Footer from '@components/Footer';
import Recaptcha from 'react-recaptcha';
import { useState } from 'react';

const ContactsPage = () => {

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isCaptchaVerefy, setIsCaptchaVerify] = useState(false);
    const [isCaptchaMessageShow, setIsCaptchaMessageShow] = useState(false);
    const URL = 'https://divineboost.org/api/contacts.php';

    const onSendForm = (e) => {
        e.preventDefault();

        if(!isCaptchaVerefy) {
            setIsCaptchaMessageShow(true);
            return false;
        }
        
        fetch(URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                email: email,
                subject: subject,
                message: message
            })
        })
    }

    const onInput = (e, type) => {
        switch(type) {
            case "email":
                setEmail(e.target.value);
                break;
            case "subject":
                setSubject(e.target.value);
                break;
            case "message":
                setMessage(e.target.value);
                break;
            default:
                return <></>
        }
    }

    var callback = function () {
        console.log('Done!!!!');
      };
      
      // specifying verify callback function
      var verifyCallback = function (response) {
         setIsCaptchaVerify(true);
         setIsCaptchaMessageShow(false);
      };

    return(
        <div>
            <Header type="mini" />
            <section className={style.contacts_section}>
                <Container>
                    <div className={style.contacts_section__title}>
                        <Title TAG="h2" text="О нас" />
                    </div>
                    <span className={style.contacts_section__sub}>
                        Если у вас есть какие-либо вопросы или проблемы, не стесняйтесь обращаться к нам.
                    </span>
                    <div className={style.contacts_section__video}>
                        <iframe width="100%" height="250"
                                src="https://www.youtube.com/embed/mFPgI4_qwTk?si=8LQ--SAb_zozFBYr"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                        </iframe>
                        <iframe width="100%" height="250"
                                src="https://www.youtube.com/embed/ZSYZuM1F1lM?si=C7VrB1X-esnwoSPc&amp;start=1"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                        </iframe>
                        <iframe width="100%" height="250"
                                src="https://www.youtube.com/embed/XxEBOoG9D_8?si=KAMqkdcqDGmbzLzB&amp;start=1"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen>
                        </iframe>
                    </div>
                    <div className={style.contacts_section__content}>
                        <div className={style.contacts_section__col}>
                            <h4 className={style.contacts_section__touch}>
                                Оставайтесь на связи
                            </h4>
                            <p className={style.contacts_section__desc}>
                                Есть вопросы? Свяжитесь с нами сегодня по электронной почте <br />
                                или через чат!
                            </p>
                            <div className={style.contacts_section__contacts}>
                                <a className={style.contacts_section__link} target="_blank" href="https://discord.gg/U7V3RUCtnu">
                                    <div className={style.contacts_section__link_image_wrap}>
                                        <img src="img/discc.png" className={style.contacts_section__link_image} />
                                    </div>
                                    <span className={style.contacts_section__link_text}>
                                        divineboost
                                    </span>
                                </a>
                                <a className={style.contacts_section__link} target="_blank" href="https://steamcommunity.com/profiles/76561199176856151/">
                                    <div className={style.contacts_section__link_image_wrap}>
                                        <img src="img/steamw.png" className={style.contacts_section__link_image} />
                                    </div>
                                    <span className={style.contacts_section__link_text}>
                                        divineboost
                                    </span>
                                </a>
                                <a className={style.contacts_section__link} target="_blank" href="https://vk.com/divineboost">
                                    <div className={style.contacts_section__link_image_wrap}>
                                        <img src="img/vks.png" className={style.contacts_section__link_image} />
                                    </div>
                                    <span className={style.contacts_section__link_text}>
                                        divineboost
                                    </span>
                                </a>
                                <a href='https://www.trustpilot.com/review/divineboost.org' className={style.trust}>
                                    <img src='img/trust.png' />
                                </a>
                            </div>
                        </div>
                        <div className={style.contacts_section__col}>
                            <form onSubmit={onSendForm}>
                                <div className={style.contacts_section__field}>
                                    <span className={style.contacts_section__label}>
                                        E-mail
                                    </span>
                                    <input required type="email" onChange={(e) => {onInput(e, "email")}} value={email} className={style.contacts_section__input} placeholder="E-mail" />
                                </div>
                                <div className={style.contacts_section__field}>
                                    <span className={style.contacts_section__label}>
                                        Тема
                                    </span>
                                    <input value={subject} onChange={(e) => {onInput(e, "subject")}} className={style.contacts_section__input} placeholder="Subject" />
                                </div>
                                <div className={style.contacts_section__field}>
                                    <span className={style.contacts_section__label}>
                                        Сообщение
                                    </span>
                                    <input value={message} onChange={(e) => {onInput(e, "message")}} required className={style.contacts_section__input} placeholder="Сообщение" />
                                </div>
                                <Recaptcha
                                    sitekey="6Lem4G0oAAAAAPVumCFaaz0y9YEmntJxrNEJa1Ld"
                                    render="explicit"
                                    theme="dark"
                                    verifyCallback={verifyCallback}
                                    onloadCallback={callback}
                                />
                                {isCaptchaMessageShow ? <span className={style.captcha}>Проверьте капчу!</span> : null}
                                <button className={style.contacts_section__send}>
                                    Отправить
                                </button>
                            </form>
                            
                        </div>
                    </div>
                    <div className={style.contacts_section__foot}>
                        В продвижении CS 2, учетных записей / и профилей Faceit.<br />
                        По всем интересующим и возникшим с нами вопросами вы можете обратиться в службу поддержки. <br />
                        <a href="mailto:artemkasatr@gmail.com">artemkasatr@gmail.com</a>
                    </div>
                </Container>
            </section>
            <Footer />
        </div>
    )
}

export default ContactsPage;