import style from './style.module.css';

import Title from '@components/Title';

const InfoBlock = ({title, infos}) => {
    return (
        <>
            {
                title ? <Title TAG="h2" text={title} /> : null
            }
            <div className={style.info_block}>
                {
                    infos.map((info, idx) => {
                        return (<article key={idx} className={style.info_block__article}>
                            <h3 className={style.info_block__title}>
                                {info.title}
                            </h3>
                            <p className={style.info_block__text}>
                                {info.text}
                            </p>
                        </article>)
                    })
                }
            </div>
        </>
        
    )
}

export default InfoBlock;