import style from './style.module.css';

import Container from "@components/Container";

const CalcDesc = ({title, desc}) => {
    return(
        <section className={style.calc_desc}>
            <Container>
                <h3 className={style.calc_desc__title}>
                    {title}
                </h3>
                <div className={style.calc_desc__text}>
                    {desc}
                </div>
            </Container>
        </section>
    )
}

export default CalcDesc;