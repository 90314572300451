import style from './style.module.css';

import Header from '@components/Header';
import Container from '@components/Container';
import Button from '@components/Button'
import WorksSection from '@components/WorksSection';
import ResultsSection from '@components/ResultsSection';
import Footer from '@components/Footer';

import { useParams, withRouter} from 'react-router';
import {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

const SellPage = () => {

    let { addId } = useParams();
    const IMAGE_SRC = 'https://divineboost.org/uploads';
    const [account, setAccount] =  useState(null)
    const [type, setType] = useState(null)
    const [currentImage, setCurrentImage] = useState('');

    useEffect(() => {
        fetch(`https://divineboost.org/api/accounts.php?id=${addId}`)
        .then((res) => {
            return res.json();
        })
        .then((account) => {
            setAccount(account[0])
            setType(account[0].type)
            
        })
    }, [])

    useEffect(() => {
        setCurrentImage(sortImages()[0] == undefined ? '' : sortImages()[0]);
    }, [type])

    const sortImages = () => {
        const order = [
            'steamimage',
            'faceitimage',
            'faceitstats',
            'gameimage',
            'other'
        ]

        const result = [];

        if(account) {
            for(let i = 0; i < order.length; i++) {
                for(let k = 0; k < account.images.length; k++) {
                    if(order[i] == account.images[k].type) {
                        result.push(account.images[k]);
                    }
                }
            }
        }

        return result;
    }

    const onImageChange = (id) => {
        setCurrentImage(sortImages().find((image) => {
            return image.id === id;
        }))
    }
    
    return(
        <div>
            <Header type="mini" />
            <div className={style.sell_page}>
                <Container>
                    <div className={style.sell_page__account_info}>
                        <div className={style.sell_page__account_images}>
                            <div className={style.sell_page__main_image_wrap}>
                                <img src={currentImage ? `${IMAGE_SRC}/${currentImage.path}` : null} alt="rank" />
                            </div>
                            <div className={style.sell_page__images_line}>
                                {
                                    sortImages().map((image, idx) => {
                                        return (
                                            <div className={`${ image.id === currentImage.id ? style.im_wrap_active + ' ' + style.im_wrap : style.im_wrap }`} onClick={() => {onImageChange(image.id)}}>
                                                <img src={`${IMAGE_SRC}/${image.path}`} alt="img" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className={style.sell_page__desc}>


                                Повышение ранга – это опция, в которой вы можете выбрать текущий и <br />
                                желаемого ранга, цена будет сгенерирована автоматически и <br />
                                скидка уже включена в общую стоимость. Повышение ранга CSGO <br />
                                доступен в 2 вариантах: Solo и Duo. <br /> <br />

                                Solo — опция совместного использования учетной записи, где мы будем играть с вашего <br />
                                аккаунт, пока не достигнем желаемого ранга. <br />

                                Dou — вариант самостоятельной игры, мы пригласим вас в лобби и <br />
                                будем играть вместе, пока не дойдем до конечной точки. <br /> <br />

                                Ищете 10 совпадений при размещении? Нажмите <a href="#">Здесь</a>.

                            </div>
                        </div>
                        <div className={style.sell_page__account__bill}>
                            <span className={style.sell_page__account_name}>
                                {account ? account.name : null}
                            </span>
                            <span className={style.sell_page__account_price}>
                                € {account ? account.price : null} Цена
                            </span>
                            <div className={style.sell_btn}>
                                <Link to='/contacts' className={style.link}>
                                    <Button text="Свяжитесь с нами" />
                                </Link>
                            </div>
                            <div className={style.sell_page__info}>
                                <span>Информация:</span>
                                {
                                    type === "faceit" ? 
                                    <ul className={style.account_card__benefits}>
                                        <li>
                                            {account ? account.name : null}
                                        </li>
                                        <li>
                                            {account ? account.elo : null} Elo
                                        </li>
                                        <li>
                                            {account ? account.points : null} Points
                                        </li>
                                        <li>
                                            {account ? account.kd : null} K/D
                                        </li>
                                        <li>
                                            Instart delivery
                                        </li>
                                    </ul> :
                                    <ul className={style.account_card__benefits}>
                                        <li>
                                            { account ? account.name : null}
                                        </li>
                                        <li>
                                            {account ? account.hours : null} Hours
                                        </li>
                                        <li>
                                            Rank: {account ? account.rank : null}
                                        </li>
                                        <li>
                                            Instart delivery
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
                <WorksSection />
                <ResultsSection />
            </div>
            <Footer />
        </div>
    )
}

export default withRouter(SellPage) ;