import style from "./style.module.css";
import Header from '@components/Header';
import Container from '@components/Container';
import Footer from '@components/Footer';

const BlogCS2Page = () => {
    return (
    <div>
        <Header type="mini"/>
        <section className={style.contacts_section}>
            <Container>
                <div className={style.content_article}>
                    <h2>Параметры запуска CS 2 для повышения ФПС (FPS)</h2>

                    <h3>Параметры запуска CS 2 для слабого ПК</h3>
                    <p>Многие параметры взяты из CS:GO. Возможно, со временем Valve может отключить поддержку некоторых из них.</p>
                    <h3>Внимание, многие параметры могут не работать.</h3>
                    <p>–d3d9ex — позволяет быстро сворачивать и разворачивать игру. Снижает нагрузку на процессор примерно на 40%.</p>
                    <p>–high — запускает CS 2 с высоким приоритетом</p>
                    <p>–freq X — задаёт частоту обновления экрана. Вместо «X» укажите параметр, соответствующий герцовке вашего монитора.</p>
                    <p>-refresh X — меняет частоту обновления экрана. Вместо «X» укажите желаемый параметр.</p>
                    <p>+fps_max 0 — убирает ограничение кадров в секунду.</p>
                    <p>+rate 124000 — устанавливает максимальное количество данных, которое может принять хост (бит/сек).</p>
                    <p>+cl_cmdrate 128 — устанавливает максимальное количество пакетов, которые отправляются на сервер в секунду.</p>
                    <p>+cl_updaterate 128 — устанавливает максимальное количество пакетов с обновлениями, которые отправляет сервер в секунду.</p>
                    <p>+ex_interpratio 1 — сетевой параметр.</p>
                    <p>–threads X — вместо «X» укажите количество ядер, которое нужно задействовать процессору.</p>
                    <p>cl_interp 0 — сглаживает картинку.</p>
                    <p>+cl_interp_ratio 1 — ускоряет подключение к серверу.</p>
                    <p>+mat_queue_mode 2 — включает многоядерную обработку игры.</p>
                    <p>-nojoy — отключает поддержку джойстика.</p>
                    <br/>
                    <p>-high -threads 6 +fps_max 0 +cl_interp 0 +cl_interp_ratio 1 +rate 124000 +cl_updaterate 128 +cl_cmdrate 128 +mat_queue_mode 2 -freq 165 -refresh 165 -d3d9ex -nojoy</p>
                    <h3>Чтобы ввести параметры, откройте библиотеку в клиенте Steam. Нажмите правой кнопкой мыши по Counter-Strike и выберите «Свойства». В самом низу вкладки «Общее» и находятся «Параметры запуска»</h3>
                    <img src="/img/cs2params.png" alt="параметры"/>
                </div>
            </Container>
        </section>
        <Footer/>
    </div>
    )
}

export default BlogCS2Page;