import style from './style.module.css';

import ResultItem from '@components/ResultItem';
import Container from '@components/Container';
import Title from '@components/Title';

const ResultsSection = () => {
    const results = [
        {
            link: '/',
            src: '/img/mmboost.jpg',
            alt: 'MatchMaking Boost',
            text: 'MatchMaking Boost Winstreak',
        },
        {
            link: '/',
            src: '/img/faceitResult.jpeg',
            alt: 'Faceit Boost',
            text: 'Faceit Boost Winstreak',
        },
        {
            link: '/',
            src: '/img/faceitResult2.jpeg',
            alt: 'Faceit Boost',
            text: 'Faceit Boost Winstreak',
        },
    ];

    return(
        <section className={style.results_section}>
            <Title TAG="h2" lcase="low" text="Наши результаты работы" />
            <Container additStyles={style.results_section__container}>
                {results.map((result, idx) => {
                    return <ResultItem key={idx} src={result.src} alt={result.alt} link={result.link} text={result.text} />
                })}
            </Container>
        </section>
    )
}

export default ResultsSection;