import style from './style.module.css';

import Container from '@components/Container'
import Title from '@components/Title';
import AdvItem from '@components/AdvItem';
import InfoBlock from '@components/InfoBlock';



const AdvSection = ({type = "info"}) => {

    const advItems = [
        {
            title: 'Никаких читов',
            desc: 'Никаких сторонних программ',
            src: './img/no-cheats.png'
        },
        {
            title: 'Быстрый сервис',
            desc: '7-9 побед в день',
            src: './img/fast-service.png'
        },
        {
            title: 'Качество',
            desc: '4.5k+ Elo & FPL-C Бустеры',
            src: './img/quality.png'
        },
    ]

    const infos = [
        {
            title: 'О нашем сервисе прокачки CS:GO: ',
            text: `Divine boost — российская компания, созданная
            бывшими профессиональными игроками и игроками в CS:GO со многими выдающимися достижениями
            в Counter-strike, таких как: FPL-C, ранг G и ранг S. Мы предлагаем самые
            профессиональные, надежные и заслуживающие доверия сервис бустинга, который удовлетворил
            тысячи клиентов по всему миру. Все члены нашей компании
            нацелены на то, чтобы сделать ваш буст максимально комфортным, приятным и увлекательным. `
        },
        {
            title: 'Почему мы?',
            text: `
            Мы предлагаем одно из лучших и дешевых 
            существующих на рынке услуг, которые могли бы
            удовлетворить любого клиента, наряду с очень дружелюбной атмосферой.
            Низкие цены, уважительное обслуживание клиентов, быстро и инновационно.
            Подход к прокачке — это то, что вас удовлетворит больше всего, приходите и
            проверьте это сами!
            `
        },
        {
            title: 'Наши сервисы:',
            text: `На данный момент наша компания предлагает две качественные услуги по подбору игроков и фейсит бустингу:

            Прокачка матчмейкинга: - доступна прокачка от серебряного до максимального ранга мировой элиты!
            
            Faceit boost: одна из самых популярных и востребованных услуг по прокачке, которую мы предлагаем. Мы можем повысить ваш ELO с 1 до 3200 ELO!  `
        },
        {
            title: 'Варианты повышения:',
            text: `Solo - совместное использование учетной записи. Самый дешевый и быстрый вариант. Бустер сам будет играть с вашего аккаунта

            Усиление лобби (50%) = играйте самостоятельно. Следующий вариант дороже и требует больше времени, однако вы получаете возможность играть самостоятельно и наслаждаться качественным геймплеем с нашим бустером, закрепленным за вашим заказом.
            
            Экспресс-буст (20%) — с опцией экспресс-буста вы сможете достичь желаемого уровня гораздо быстрее, поскольку ваша учетная запись будет иметь больший приоритет над другими входящими заказами!`
        },

    ];

    return(
        <section className={style.adv_section}>
            <Container>

                {type === "info" ?
                    <>
                        <Title TAG="h2" text="Опыт обслуживания более 5 лет" />
                        <div className={style.adv_section__desc_wrap}>
                            <p className={style.adv_section__desc}>
                                Основная цель компании – помогать нашим клиентам прогрессировать
                                в области конкурентного CS. Чтобы преодолеть
                                любые возможные разочарования и прогресс дальше, показывая путь
                                в игру Counter-Strike можно играть с другой, профессиональной точки зрения.
                            </p>
                            <p className={style.adv_section__desc}>
                                Если у вас есть какие-либо вопросы, пожалуйста, свяжитесь с нами. Мы здесь чтобы помочь вам!
                            </p>         
                        </div>
                    </>
                     : 
                    null
                 }

                
                <div className={style.adv_section__advantages}>
                    {
                        advItems.map((advItem, idx) => {
                            return <AdvItem key={idx} title={advItem.title} desc={advItem.desc} src={advItem.src} />
                        })
                    }
                </div>

                {type === "info" ? <InfoBlock infos={infos} /> : null}
                
                
            </Container>
        </section>
    )
}

export default AdvSection;