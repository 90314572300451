import style from "./style.module.css";
import Header from '@components/Header';
import Container from '@components/Container';
import Footer from '@components/Footer';

const BlogConfigCs2 = () => {
    return (
    <div>
        <Header type="mini"/>
        <section className={style.contacts_section}>
            <Container>
                <div className={style.content_article}>
                    <h2>Конфиг CS 2 — как установить новый конфиг в КС 2</h2>

                    <p>В CS 2 доступна опция конфигов — личных настроек игрока в шутере. Однако Valve немного изменила условия для его переноса из CS:GO. Ниже представлен гайд, как установить новый конфиг в КС 2.</p>
                    <h3>Как перенести конфиг из КСГО в Counter-Strike 2</h3>
                    <p>Зайдите в папку с конфигом CS:GO, где хранится файл «config.cfg». Он находится в следующей ветке:</p>
                    <p>Steam/userdata/id аккаунта/730/local/cfg</p>

                    <h3>Как установить новый конфиг в КС 2</h3>
                    <p>Valve скорректировала путь для установки конфига в CS 2. Возьмите скопированный файл config.cfg из папки CS:GO и перенесите его в следующую ветку:</p>
                    <p>Steam/steamapps/common/Counter Strike:Global Offensive/game/csgo/cfg</p>

                    <h3>Как включить конфиг в CS 2</h3>
                    <p>Зайдите в шутер и вызовите консоль через кнопку «~». Затем пропишите команду «exec config.cfg» без кавычкек. Вы можете изменить название файла на любое слово вместо «config». Учтите, что не все параметры конфига могут перейти в КС 2.</p>
                </div>
            </Container>
        </section>
        <Footer/>
    </div>
    )
}

export default BlogConfigCs2;