import style from './style.module.css';

import {
    Link
} from "react-router-dom"; 

const AccountCard = ({data}) => {
    return(
        <Link to={`acc/${data.id}`} className={style.account_card}>                    
            <div className={style.account_card__image_wrap}>
                <img className={style.account_card__image} src={`https://divineboost.org/uploads/${data.images[0] ?data.images[0].path : null }`} alt={data.name} />
            </div>
            <div className={style.account_card__info}>
                {
                    data.type === "faceit" ? 
                    <ul className={style.account_card__benefits}>
                        <li>
                            {data.name}
                        </li>
                        <li>
                            {data.elo} Elo
                        </li>
                        <li>
                            {data.points} Points
                        </li>
                        <li>
                            {data.kd} K/D
                        </li>
                        <li>
                            Instart delivery
                        </li>
                    </ul> :
                    <ul className={style.account_card__benefits}>
                        <li>
                            {data.name}
                        </li>
                        <li>
                            {data.hours} Hours
                        </li>
                        <li>
                            Rank: {data.rank}
                        </li>
                        <li>
                            Instart delivery
                        </li>
                    </ul>
                }
                
                <span className={style.account_card__price}>
                    €{data.price}
                </span>
            </div>
        </Link>
    )
}

export default AccountCard