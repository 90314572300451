import style from "./style.module.css";
import Header from '@components/Header';
import Container from '@components/Container';
import Footer from '@components/Footer';

const ArticlePage = () => {
    return (
    <div>
        <Header type="mini"/>
        <section className={style.contacts_section}>
            <Container>
                <div className={style.content_article}>
                    <h2>Настраиваем параметры запуска в КС ГО</h2>

                    <p>Параметры запуска это некие игровые пресеты, которые подсказывают игре как правильно работать. Вот так выглядят мои личные параметры запуска: -freq 240 -d3d9ex -novid -no-browser +mat_queue_mode 2 +fps_max 400 +cl_interp_ratio 1 +cl_interp 0 -tickrate 128 +cl_cmdrate 128 +cl_updaterate 128 +cl_forcepreload 1</p>
                    <p>-freq 240 — Устанавливает ограничение частоты обновления монитора.</p>
                    <p>-d3d9ex — Параметр, который уменьшает использование памяти процессора примерно на 40%, игра минимизируется и разворачивается быстрее, ее работа более стабильна. Помогает не всем.</p>
                    <p>-novid — Отключает заставку при запуске игры</p>
                    <p>-no-browser — Оптимизация Steam Overlay</p>
                    <p>+mat_queue_mode 2 — Очень полезная команда, поскольку она включает многоядерную обработку, а так же повышает производительность игры.</p>
                    <p>+fps_max 400 — Ограничение фпс до 400 (Варьируется от случая к случаю, мы не рекомендуем устанавливать значение 0, так как var и sv будут нестабильны)</p>
                    <p>+cl_interp_ratio 1, +cl_interp 0 — Основные настройки интерполяции на стороне клиента</p>
                    <p>-tickrate 128 — Предустановленная частота тикрейта составляет 128; на серверах вашего лобби (для тренировок) частота тикрета также будет 128.</p>
                    <p>+cl_cmdrate 128, +cl_updaterate 128 — Настройка рейтов</p>
                    <p>+cl_forcepreload 1 — Все временные файлы загружаются перед входом на сервер, что повышает производительность.</p>
                </div>
            </Container>
        </section>
        <Footer/>
    </div>
    )
}

export default ArticlePage;