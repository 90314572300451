import style from './style.module.css';


const BoostCard = ({image, text, link}) => {
    return(
        <a className={style.boost_card} href={link}>
            <img className={style.boost_card__image} src={image} />
        </a>
    )
}

export default BoostCard;