import style from './style.module.css';

const SocialMedia = () => {

    const socials = [
        {
            src: '/img/vk.png',
            link: 'https://vk.com/divineboost',
        },
        {
            src: '/img/discord.png',
            link: 'https://discord.gg/U7V3RUCtnu',
        },
        {
            src: '/img/steam.png',
            link: 'https://steamcommunity.com/profiles/76561199176856151/',
        },
    ]

    return(
        <div className={style.social_media}>
            {socials.map((social, idx) => {
                return <a target="_blank" rel={"noreferrer"} href={social.link} key={idx} className={style.social_media__link}>
                    <img className={style.social_media__icon} src={social.src} alt="social media" />
                </a>
            })}
        </div>
    )
}

export default SocialMedia;