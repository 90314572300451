import style from './style.module.css';

import CalcDesc from '@components/CalcDesc';
import Container from '@components/Container';
import Header from '@components/Header';
import Row from '@components/Row';
import Col from '@components/Col';
import AccountCard from '@components/AccountCard';
import AccountContainer from '@components/AccountContainer';
import Footer from '@components/Footer';

import { useEffect, useState } from 'react';

const AccountsPage = () => {
    const [accounts, setAccounts] = useState([]);
    
    useEffect(() => {
     fetch('https://divineboost.org/api/accounts.php')
     .then((results) => {
        return results.json();
     })
     .then((accs) => {
        setAccounts(accs)
     })
    }, [])
    const levelsType = [
        "Faceit уровень 4-10",
        "Среднее/Высокое Elo",
        "Высокий уровень",
        "Готовые Faceit аккаунты",
        "Готовые Steam аккаунты"
    ];
    return(
        <div className={style.accounts_page}>
            <Header type="mini" />
            <div className={style.accounts_page__desc}>
                <CalcDesc
                    title={`Купить/Продать CSGO Faceit`}
                    desc={`
                        Мы - проверенные продавцы с более чем 5-летним опытом работы и теперь с гордостью представляем
                        Самый большой спектр аккаунтов faceit и esea на рынке.
                        Наши цены конкурентоспособны, наши услуги высокого качества, мы известны своей оперативностью и
                        эффективность.
                        Не можете найти подходящий аккаунт/цену? Поговорите с нами об этом, и мы поможем вам как можно скорее.
                    `}
                />
            </div>    
            <Container>
                {levelsType.map((title)=>(
                    <AccountContainer key={title} title={title}>
                    <Row>
                        {accounts.filter((account)=>account.elo >= 1101).map((account) => (
                            <Col key={account.id}>
                                <AccountCard data={account} />
                            </Col>
                        ))}
                    </Row>
                </AccountContainer>
                ))}
            </Container>
            <Container>
                <div className={style.accounts_page__offers}>
                    <h5>
                        Предложения по учетной записи:
                    </h5>
                    <p>
                        1. Мачмейкинг и фейсит аккаунты (как Prime, так и Nonprime - любой ранг). <br />
                        2. Уровень Faceit 3 4 5 6 7 8 9 - 10-й уровень, 2000 аккаунтов Elo +, включая профиль Steam и CSGO.
                    </p>
                    <p>
                        После покупки вы получите информацию об учетной записи (логин faceit/steam, пароль faceit/steam и первый <br />
                        данные по электронной почте) на ваш указанный электронный адрес. У всех аккаунтов отключена steam guard, вы можете мгновенно подключиться к аккаунту <br />
                        и добавьте свой телефон, измените адрес электронной почты и все, что захотите.
                    </p>
                    <p>
                        Купите или продайте свой аккаунт в CSGO / Faceit <br />
                        https://divineboost.ru/
                    </p>
                </div>
            </Container>
            <Footer type="mini" />
        </div>
    )
}

export default AccountsPage;