import style from './style.module.css';

import Header from '@components/Header';
import Title from '@components/Title';
import Container from '@components/Container';
import Footer from '@components/Footer';
import {Link} from "react-router-dom";

const BlogPage = () => {
    const Items = [
        {
            id: 1,
            title: "Лучшие Параметры Запуска КС ГО",
            text: `Не стоит игнорировать параметры запуска, поскольку они оптимизируют и улучшают производительность игры. Мы поделились наиболее полезными из них.`,
            src: '/img/csblog.jpg',
            alt: 'Лучшие Параметры Запуска КС ГО',
            link: '/best-CS-GO-launch-options'
        },
        {
            id: 2,
            title: "Параметры CS 2 для повышения ФПС (FPS)",
            text: `CS 2 более требовательна к производительности компьютера, чем предыдущая часть. Из-за этого многие игроки сталкиваются с лагами и низким FPS, что не позволяет комфортно проводить время. В этой статье мы приведём полезные параметры запуска для повышения ФПС в КС 2.`,
            src: '/img/cs2.jpeg',
            alt: 'Повышение ФПС CS 2',
            link: '/CS-2-options-to-increase-fps'
        },
        {
            id: 3,
            title: "Лучшие белые скины в cs",
            text: `Белый — ультимативный. Он идеально сочетается с любым другим цветом, подсвечивая детали и оттенки. Если ты всегда хотел обзавестись парочкой белых скинов, Блог CS.MONEY пришел на помощь! Мы сделали подборку лучших белых скинов 2023 года, приправив изображениями, видео-анимацией и ценовыми графиками. Открывай и смотри!`,
            src: '/img/belye-skiny-ksgo.jpeg',
            alt: 'skins',
            link: '/the-best-white-skins-in-cs'
        },
        {
            id: 4,
            title: "Конфиг CS 2 — как установить новый конфиг в КС 2",
            text: `В CS 2 доступна опция конфигов — личных настроек игрока в шутере. Однако Valve немного изменила условия для его переноса из CS:GO. Ниже представлен гайд, как установить новый конфиг в КС 2.`,
            src: '/img/cs2blue.webp',
            alt: 'config cs2',
            link: '/config-cs2'
        },
    ];

    return(
        <div>
            <Header type="mini" />
            <section className={style.contacts_section}>
                <Container>
                    <div className={style.contacts_section__title}>
                        <Title TAG="h2" text="Блог" />
                    </div>
                    <span className={style.contacts_section__sub}>
                        Здесь вы можете узнать последние новости из мира CS 2.
                    </span>
                    <div className={style.content_wrapper}>
                        {Items.map((item) => (
                            <div key={item.id} className={style.content_card}>
                                <Link className={style.content_link} to={item.link}>
                                    <img className={style.content_img} src={item.src} width={280} height={200} alt={item.alt} />
                                    <h2 className={style.content_title}>{item.title}</h2>
                                    <p className={style.content_text}>{item.text}</p>
                                </Link>
                            </div>
                        ))}
                    </div>
                </Container>
            </section>
            <Footer />
        </div>
    )
}

export default BlogPage;