import style from './style.module.css';

const Checkbox = ({onCheckBoxClick, value, id}) => {
    return(
        <div className={style.checkbox}> 
            <label className={style.checkbox__label}>
                <input className={style.checkbox__hidden} type="checkbox" defaultChecked={value} />
                <div className={style.checkbox__custom} onClick={()=>onCheckBoxClick(id)} id={id}>
                    <div className={style.cheсkbox__controller}></div>
                </div>
            </label>
        </div>
    )
}

export default Checkbox;