import style from './style.module.css';

import { useState } from 'react';
import Recaptcha from 'react-recaptcha';

import Logo from '@components/Logo'

const Modal = ({isOpened, closeModal, from, to, price, lobby, express}) => {

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isCheckOpen, setIsCheckOpen] = useState(false);
    const [isCaptchaVerefy, setIsCaptchaVerify] = useState(false);
    const [isCaptchaMessageShow, setIsCaptchaMessageShow] = useState(false);
    const URL = 'https://divineboost.org/api/request.php';

    const onInput = (e, type) => {
        switch(type) {
            case "email":
                setEmail(e.target.value);
                break;
            case "subject":
                setSubject(e.target.value);
                break;
            case "message":
                setMessage(e.target.value);
                break;
            default:
                return <></>;
        }
    }

    const onSendForm = (e) => {

        e.preventDefault();

        if(!isCaptchaVerefy) {
            setIsCaptchaMessageShow(true);
            return false;
        }

        fetch(URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                subject: subject,
                message: message,
                from: from,
                to: to,
                price: price,
                lobby: lobby,
                express: express
            })
        })

        setIsCheckOpen(true)
    }

    const onCloseModal = (e) => {
        closeModal(e)
        setIsCheckOpen(false)
    }
    var callback = function () {
        console.log('Done!!!!');
      };
      
      // specifying verify callback function
      var verifyCallback = function (response) {
         setIsCaptchaVerify(true);
         setIsCaptchaMessageShow(false);
      };

    return(
        <>
            {isOpened ?
                <div className={style.modal_wrap}>
                    <div className={style.modal}>
                        <div className={style.modal__close} onClick={onCloseModal}>
                            <span></span>
                            <span></span>
                        </div>
                        {!isCheckOpen ? 
                            <form onSubmit={onSendForm}>
                                <h2 className={style.modal__title}>Форма заявки</h2>
                                <div className={style.contacts_section__field}>
                                    <span className={style.contacts_section__label}>
                                        Discord
                                    </span>
                                    <input value={subject} required className={style.contacts_section__input} onChange={(e) => {onInput(e, "subject")}}  placeholder="Discord" />
                                </div>
                                <div className={style.contacts_section__field}>
                                    <span className={style.contacts_section__label}>
                                        Сообщение
                                    </span>
                                    <input value={message} className={style.contacts_section__input} onChange={(e) => {onInput(e, "message")}} required placeholder="Message" />
                                </div>
                                <Recaptcha
                                    sitekey="6Lem4G0oAAAAAPVumCFaaz0y9YEmntJxrNEJa1Ld"
                                    render="explicit"
                                    theme="dark"
                                    verifyCallback={verifyCallback}
                                    onloadCallback={callback}
                                />
                                {isCaptchaMessageShow ? <span className={style.captcha}>Verify captcha!</span> : null}
                                <div className={style.contacts_section__wrapper}>
                                    <button className={style.contacts_section__send} type="submit">
                                        Отправить сообщение
                                    </button>
                                    <span>Сделать заказ прямо <a href="https://vk.com/divineboost">сейчас</a></span>
                                </div>
                            </form> : null
                        }
                        
                        { isCheckOpen ?
                            <div className={style.modal__check}>
                                <div>
                                    <div>
                                        <h2>Receipt</h2>
                                        Boost from: {from} <br />
                                        Boost to: {to} <br />
                                        Price: {price}р <br />
                                        Lobby boost: {lobby ? 'on' : 'off'} <br />
                                        Express boost: {express ? 'on' : 'off'} <br />
                                    </div>
                                    <span className={style.modal__check_order}>
                                        Мы свяжемся с вами через
                                        Discord, чтобы начать делать заказ
                                    </span>
                                </div>
                                <Logo src="/img/logo.png" />
                            </div> :
                            null
                        }
                    </div>
                </div> : null
            }

        </>
        
    )
}

export default Modal;