import style from './style.module.css';

import Title from '@components/Title';
import Container from '@components/Container';

const DescSection = () => {
    return(
        <section className={style.desc_section}>
            <Container>
                <div className={style.desc_section__title}>
                    <Title TAG="h3" text="Сервис повышения рейтинга CS 2" />
                </div>
                <div className={style.desc_section__text}>
                    Сервис повышения рейтинга CS 2 - это опция, где вы можете купить желаемый ранг, победу или место.
                    При повышении ранга размещения максимальный ранг, которого вы достигнете, — Legendary Eagle Master.
                    Повышение ранга доступно как в одиночном, так и в парном режиме.
                    Мы предлагаем усиление в ЕС и Северной Америке, для других регионов мы можем предоставить только одиночный режим, или вы можете присоединиться к лобби.
                    но у вас будет пинг более 100.
                    Наш сервис также предлагает прокачку ведомого в ксго за каждый ранг.
                    Наши цены дешевле по сравнению с нашими конкурентами и в то же время, навыки и качество в целом.
                    когда дело доходит до CS 2, бусты находятся на максимально возможном уровне.
                </div>
            </Container>
        </section>
    )
}

export default DescSection;