import style from './style.module.css';

const AdvItem = ({title, desc, src}) => {
    return(
        <div className={style.adv_item}>
            <div className={style.adv_item__circle_outer}>
                <div className={style.adv_item__circle_inner}>
                    <img className={style.adv_item__icon} src={src} />
                </div>
            </div>
            <h3 className={style.adv_item__title}>
                {title}
            </h3>
            <p className={style.adv_item__desc}>
                {desc}
            </p>
        </div>
        
    )
}

export default AdvItem;