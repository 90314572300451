import style from './style.module.css';

const AccountCantainer = ({children, title}) => {
    return(
        <div className={style.account_container}>
            <h2 className={style.account_container__title}>
                {title}    
            </h2>
            <div className={style.account_container__accounts}>
                {children}
            </div>
        </div>
    )
}

export default AccountCantainer;